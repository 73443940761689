import { isFetchError, useGetApiError } from '~/lib/frontend/common/composables/fetch'
import { useGetFunction } from '~/lib/frontend/functions/composables/management'

export default defineNuxtRouteMiddleware(async (to) => {
  const functionId = to.params['fid'] as string
  const fn = useGetFunction(functionId || '')
  const getError = useGetApiError()
  const logger = getLogger()

  try {
    const res = await fn.promise
    if (res.data.value?.functionId) {
      return undefined
    }

    throw res.error.value || new Error("Couldn't resolve function")
  } catch (e) {
    const err = getError(e)
    if (isFetchError(err)) {
      const isNotFound = [404, 422].includes(err.statusCode || 1)
      const isForbidden = [403, 401].includes(err.statusCode || 1)

      if (isNotFound) {
        return abortNavigation(
          createApiError({
            statusCode: 404,
            message: 'Function not found',
            statusMessage: 'FunctionNotFound'
          })
        )
      }

      if (isForbidden) {
        return abortNavigation(
          createApiError({
            statusCode: 403,
            message: 'You do not have access to the specified function',
            statusMessage: 'NoFunctionAccess'
          })
        )
      }
    }

    logger.error(err)
    return abortNavigation(
      createApiError({
        statusCode: 500,
        message: `${err.message}`,
        statusMessage: 'UnexpectedError'
      })
    )
  }
})
